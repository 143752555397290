
<template>
  <div id ="helpcentermodalmodal" class = "pagefixedwidth2">

    <b-modal size ="l-x" @hidden= "openguidemodal = false" v-model="openguidemodal" >

      <template v-slot:modal-header="{cancel}">
        <h3 class="modal-title">First time here?</h3>
        <a class = "close-modal dialog-close-button mr-2">
          <i class="fal fa-times fa-lg fa-lg " @click="openguidemodal=false" ></i>
        </a>
      </template>
      <p>
        Would you like to read the 'Getting started with Flowe' guide? The guide will open in a new browser tab or window.
      </p>
      <template v-slot:modal-footer="{ ok,cancel }">
        <b-button @click="openguidemodal=false">Cancel</b-button>      
        <a class="btn-primary btn" href = "https://goflowe.atlassian.net/servicedesk/customer/portal/1/article/725057549" @click="openguidemodal=false">Open guide</a>               
      </template>
    </b-modal>

    

    <TopBar >
      <template v-slot:title>
        Help 
      </template>
      <template v-slot:righticons>
        <a class="btn btn-primary mr-2" href="https://flowe.io/support" target="_blank">Contact support</a>
      </template>
    </TopBar>
    <div class = "main-wrapper" >
      
      <aboutpage></aboutpage>
    </div>
  </div>

</template>

<style type="text/css">
  .confluenceTable{
    border: solid 1px var(--N200); 
    border-color: var(--N200);
    border-collapse: collapse;
  }
  .confluenceTh{
    border: solid 1px var(--N200); 
    border-color: var(--N200);
    padding: 5px;
    background-color: var(--N100); 
  }

  .confluenceTh p{
    margin: 0;
  }

  .confluenceTd{
    border: solid 1px var(--N200); 
    border-color: var(--N200);
    padding: 5px;
  }

  .helppagecontent p{
    margin-bottom: 2rem;
  }

</style>

<style  lang="scss" scoped>
@import '@/scss/abstracts/variable.scss';
.card:hover{
  box-shadow: 0 14px 26px -12px rgba(73, 96, 146, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(73, 96, 146, 0.2) !important;
}
a:hover{
  font-weight: $fw-bold;
  &.text-primary{
    font-weight: 400;
  }
}

.help-gruppe{
  padding:3rem 0;
  border-bottom: 2px solid $N200;
}

.help-gruppe-title{
  margin-bottom: 10px;
  display:flex;
  .title-icon{
    width:60px;
    height:60px;
     line-height: 40px;
    background-color:$B500;
    border-radius:100%;
    padding:10px
  }
  
}


a.d-flex{
  cursor: pointer;
}
a.card-block:hover, a.d-flex:hover{
  text-decoration: none;
  background-color:#ffffff;
  color: $B500 !important;
}

.help-list{
  padding: 0px;

  li {

    list-style: none;

  }
  li .sub {

    padding: 5px 20px 5px;

  }

  > li {
    height:auto;
    > a{
      padding: 10px 0;
      font-weight: $fw-bold !important;

    }

  }

  li a {
    display: block;

  }

}

.bbrand{
  height: 45px;
}

</style>
<script type="text/javascript"></script>
<script>
  import {TopBar} from '@/components/topbar'
  import {bus} from '@/main'

  import aboutpage from'./aboutpage' 
  export default{
    name:'helpContent',
    data(){
      return{
        showaboutFlowe:false,
        openguidemodal:false
      }

    },
    components:{
      TopBar, aboutpage
    },

    computed:{
      showhints(){
        return this.$store.state.showhints
      }
    },

    mounted(){
      $('#preloader').addClass('hide')
      if(this.$route.query.page == 'about'){
        this.showaboutFlowe = true
        var aboutpanel = document.getElementById('aboutpanel');
        aboutpanel.scrollIntoView();
      }
      this.$nextTick(function () {
        document.title ='Help | Flowe'
      })
    },


    methods:{
      showhint(){
        this.$store.commit('SET_SHOWHINTS',!this.showhints)
      }
    },
  }
</script>